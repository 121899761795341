import { routerPermission } from "./permission";
import store from "@/store";

export function beforeRouterEach(to, from, next) {
  if (to.matched.some((route) => route.meta.public)) {
    // 如果是公共页，允许访问
    next();
  } else {
    const userRole = store.state.user.role;
    const token = localStorage.getItem("token");
    if (!userRole || !token) {
      // 未登录，重定向到登录页面
      next("/");
      return;
    } else {
      // 已登录，可以路由到有权限的页面
      const routerList = userRole.reduce(
        (acc, role) => acc.concat(routerPermission[role]),
        []
      );
      const hasAccess = routerList.some((item) => item.path === to.path);
      if (hasAccess) {
        const toastBox = document.querySelector("#toast-box");
        if (toastBox) {
          while (toastBox.firstChild) {
            toastBox.removeChild(toastBox.firstChild);
          }
        }
        next();
        return
      } else {
        // 已登录，路由到无权限的页面
        next("/noaccess");
        return
      }
    }
  }
}

