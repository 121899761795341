
const routes = [
  {
    path: "/",
    redirect: "/login",
    meta: { public: true },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/login/index.vue"),
    meta: { public: true },
  },

  {
    path: "/noaccess",
    name: "noaccess",
    component: () => import("@/pages/noaccess/index.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/pages/dashboard/index.vue"),
  },
  {
    // 房间详情页
    path: "/roomdetail",
    name: "roomdetail",
    component: () => import("@/pages/dashboard/roomDetail.vue"),
  },

  {
    path: "/activehistory",
    name: "activehistory",
    component: () => import("@/pages/logbook/index.vue"),
  },
  {
    path: "/sensor",
    name: "sensorManagement",
    component: () => import("@/pages/sensor/index.vue"),
  },
  {
    path: "/floorplan",
    name: "floorplanManagement",
    component: () => import("@/pages/floorplan/index.vue"),
  },
  {
    path: "/organization",
    name: "organizationManagement",
    component: () => import("@/pages/organization/index.vue"),
    children: [
      {
        path: "org",
        name: "org",
        component: () => import("@/pages/organization/organization.vue"),
      },
      {
        path: "department",
        name: "department",
        component: () => import("@/pages/organization/department.vue"),
      },
      {
        path: "home",
        name: "home",
        component: () => import("@/pages/organization/home.vue"),
      },

    ],
  },
];

export default routes;
