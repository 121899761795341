<template>
  <transition name="toast">
    <div class="toast-wrapper" :class="type" v-if="visible">
      <div class="title">
        <div class="title-text">
          {{ title }}
          <span class="icon">
            <img v-if="type === 'success'" src="../../assets/icon/ic_toast-success.png" alt="">
            <img v-if="type === 'error'" src="../../assets/icon/ic_toast-warning.png" alt="">
          </span>
        </div>
        <div class="btn-close" @click="close"></div>
      </div>
      <div class="toast-tips">
        <span>{{ text }}</span>
        <span v-if="code">Error code: {{ code }}</span>
      </div>
      <div class="line" :class="{ 'success': type === 'success', 'error': type === 'error' }"></div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      visible: true,
      text: '',
      code: '',
      type: 'error'
    };
  },
  computed: {
    title() {
      return this.type === 'success' ? 'Success' : 'Error';
    },
  },
  methods: {
    close() {
      this.visible = false;
      this.$el.addEventListener('transitionend', () => {
        this.$el.parentNode && this.$el.parentNode.removeChild(this.$el);
      });
    }
  },
  created() {
    this.$on('close', this.close); // 监听关闭事件
  }
};
</script>

<style lang="scss" scoped>
.toast-enter-active, .toast-leave-active {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.toast-enter {
  transform: translateY(100%);
  opacity: 0;
}

.toast-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

.toast-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 400px;
  border-radius: 10px;
  box-shadow: 0 10px 10px 0 rgba(85, 88, 181, 0.1);
  background-color: #fff;
  overflow: hidden;
  margin: 10px 0;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 20px 0 20px;

    .title-text {
      display: flex;
      align-items: center;
      color: #222e61;
      font-size: 16px;
      font-weight: 500;

      .icon {
        width: 18px;
        height: 18px;
        margin-left: 5px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .btn-close {
      width: 20px;
      height: 20px;
      background: url(../../assets/icon/ic_sensor_edit_cancel.png) no-repeat center;
      background-size: 18px;
      cursor: pointer;
    }
  }

  .toast-tips {
    display: flex;
    flex-direction: column;
    color: #222e61;
    font-size: 14px;
    margin: 5px 20px;
    text-align: left;
    padding: 5px 0;

    span {
      margin-top: 3px;
    }
  }

  .line {
    width: 100%;
    height: 5px;
  }

  .success {
    background-color: #2bc248;
  }

  .error {
    background-color: #ff2c50;
  }
}
</style>
