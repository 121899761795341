import Vue from 'vue';
import Toast from './index.vue';

function removeChild(el){
  el.forEach(toastEl => {
    if (toastEl && toastEl.__vue__) {
      toastEl.__vue__.$emit('close'); // 触发每个实例的关闭事件
    }
  });
}


const toast = async (text = '', code = '', type = 'error', parentEl = '#toast-box') => {
  // 获取所有现有的 toast 实例
  const previousToasts = document.querySelectorAll(`${parentEl} .toast-wrapper`);

  await removeChild(previousToasts)

  const Constructor = Vue.extend(Toast);
  const instance = new Constructor({
    data: {
      text,
      code,
      type
    }
  }).$mount();

  instance.visible = true;

  const pEl = document.querySelector(parentEl);
  pEl.appendChild(instance.$el);

  const rect = pEl.getBoundingClientRect();
  const left = rect.left + rect.width / 2;

  instance.$el.style.left = `${left}px`;
};

export default toast;

