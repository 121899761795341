<template>
  <transition name="toast">
    <div class="toast-wrapper"  v-if="visible">
      <div class="content">
        <div class="left">
          <img src="../../assets/img/img-no-internet.png" alt="">
        </div>
        <div class="right">
          <div class="title">
            <span>{{ title }}</span>
            <span class="btn-close" @click="close"></span>
          </div>
          <div class="text">{{ text }}</div>
        </div>
      </div>
      <div class="line"></div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      text: "",
      title: "",
      visible: true,

    };
  },
  methods: {
    close() {
      this.visible = false;
      this.$el.addEventListener('transitionend', () => {
        this.$el.parentNode && this.$el.parentNode.removeChild(this.$el);
      });
    }
  },
  created() {
    this.$on('close', this.close); // 监听关闭事件
  }
};
</script>
<style lang="scss" scoped>
.toast-enter-active, .toast-leave-active {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.toast-enter {
  transform: translateY(100%);
  opacity: 0;
}

.toast-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

.toast-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  border-radius: 10px;
  box-shadow: 0 10px 10px 0 var(--iris-10);
  background-color: #fff;
  overflow: hidden;
  margin: 10px 0;

  >.content {
    display: flex;
    padding: 15px;

    .left {
      width: 50px;
      height: 50px;

      >img {
        width: 100%;
        height: 100%;
      }
    }

    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-left: 15px;

      .title {
        display: flex;
        justify-content: space-between;

        span {
          color: #222e61;
          font-size: 16px;
          font-weight: 500;
        }

        .btn-close {
          width: 20px;
          height: 20px;
          background: url('../../assets/icon/ic_sensor_edit_cancel.png') no-repeat center;
          background-size: 18px;
          cursor: pointer;
        }
      }

      .text {
        color: #222e61;
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }



  >.line {
    width: 100%;
    height: 5px;
    background-color: #ff9b40;
  }
}
</style>

