import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import { beforeRouterEach } from "./utils";

Vue.use(VueRouter);

const router=new VueRouter({
    mode:'hash',
    routes,
})


router.beforeEach(beforeRouterEach);
export default router;
