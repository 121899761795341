
import { SET_USERROLE,SET_USERNAME,SET_USERID } from "../mutations";
import mutations from "../mutations";

const storeRole=localStorage.getItem('role')

const state = {
  role: JSON.parse(storeRole)|| [],
  username:localStorage.getItem('username')||"",
  userId:localStorage.getItem('userId')||"",
};

const actions = {
  setRole({ commit }, role) {
    commit(SET_USERROLE, role);
  },

  setUsername({commit},username){
    commit(SET_USERNAME,username)
  },
  setUserId({commit},userId){
    commit(SET_USERID,userId)
  }


};

const getters = {
  getRole: (state) => state.role,

  getUsername:(state)=>state.username,
  getUserId:(state)=>state.userId

};

export default {
  state,
  mutations,
  actions,
  getters
};