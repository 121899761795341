import axios from "axios";

import process from "process";
import networkToast from "@/components/network-toast/index"

import { translate } from "@/locales/index";

let API_BASE_URL ='api'

// 创建axios实例
const _axios = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

// 请求拦截器
_axios.interceptors.request.use(
  (config) => {
    //判断网络是否正常
    let network = navigator.onLine;
    if (!network) {
      //网络异常
      networkToast(translate('p0010error'),translate('p0010neterrdsc'));
      return Promise.reject("Network connection error");
    } else {
      //添加请求头
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `${token}`;
      }
      return config;
    }
  },
  (error) => {
    // 处理错误请求
    return Promise.reject(error);
  }
);

// 响应拦截器
_axios.interceptors.response.use();

export default _axios;
