// navigation.js

export const navigationConfig = {
  3: [
    { title: "p0001sensormanagement", path: "/sensor" }, //sensor Management
    { title: "p0001floorplanmanagement", path: "/floorplan" }, //Floor Plan Management
  ],
  2: [
    { title: "p0001dashboard", path: "/dashboard" }, //Dashboard
    { title: "p0001logbook", path: "/activehistory" }, //Logbook
  ],
  1: [{ title: "p0001orgmanagement", path: "/organization" }], //Organization Management
};

export const routerPermission = {
  3: [
    { path: "/" },
    { path: "/login" },
    { path: "/sensor" },
    { path: "/floorplan" },
    { path: "/noaccess" },
  ],
  2: [
    { path: "/" },
    { path: "/login" },
    { path: "/dashboard" },
    { path: "/roomdetail" },
    { path: "/activehistory" },
    { path: "/activehistory/timeline" },
    { path: "/activehistory/gantt" },
    { path: "/noaccess" },
  ],
  1: [
    { path: "/" },
    { path: "/login" },
    { path: "/organization" },
    { path: "/organization/org" },
    { path: "/organization/department" },
    { path: "/organization/home" },
    { path: "/noaccess" },
  ],
};

export const showNotification = [
  "/dashboard",
  "/roomOccupied",
  "/offlineDetail",
  "/roomdetail",
];
