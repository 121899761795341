<template>
    <div class="upgrade-banner__wrapper">
        <div class="ic-upgrade"></div>
        <div class="upgrade-title">{{ $t('p0011sysupgtt')}}</div>
        <div class="upgrade-text">{{ $t('p0011sysupgmsga') }} {{ formatTimestampToTimezone(timestamp,userTimezone) }} {{ $t('p0011sysupgmsgb') }}</div>
    </div>
</template>

<script>
import store from '@/store';
import moment from "moment";

export default {
    name: "Upgradebanner",
    data() {
        return {
            userTimezone: new Date().getTimezoneOffset(),
        }
    },
    computed:{
        timestamp(){
            return Number(store.state.system.upgradeTime)*1000
        }
    },
    methods: {
        formatTimestampToTimezone(timestamp, userTimezone) {
            return moment(timestamp)
                .utcOffset(-userTimezone)
                .format("YYYY-MM-DD HH:mm:ss");
        },
    }
}
</script>

<style lang="scss" scope>
.upgrade-banner__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    background-color: #ededf8;
    padding: 0 20px;

    >.ic-upgrade {
        width: 24px;
        height: 24px;
        background: url('../../assets/icon/ic-update.png') no-repeat center;
        background-size: contain;
    }

    >.upgrade-title {
        font-size: 16px;
        font-weight: 500;
        color: #222e61;
    }

    >.upgrade-text {
        font-size: 14px;
        color: #222e61;
        margin-left: 10px;
    }
}
</style>