import { SET_ORGID, SET_ORGNAME, SET_TREEID,SET_HOMELIST,SET_CURRENTHOME,SET_TREENAME } from "../mutations";
import mutations from "../mutations";

const state = {
  orgId: localStorage.getItem("orgId") || "",
  orgName: localStorage.getItem("orgName") || "",
  treeId:localStorage.getItem('treeId')||"",
  treeName:localStorage.getItem('treeName')||"",
  homeList: JSON.parse(localStorage.getItem("homeList")) || "",
  currentHome: JSON.parse(localStorage.getItem("currentHome")) || "",
};

const actions = {
  setOrgId({ commit }, orgId) {
    commit(SET_ORGID, orgId);
  },
  setOrgName({ commit }, orgName) {
    commit(SET_ORGNAME, orgName);
  },
  setTreeID({ commit }, treeId) {
    commit(SET_TREEID, treeId);
  },
  setTreeName({ commit }, treeName) {
    commit(SET_TREENAME, treeName);
  },
  setHomeList({ commit }, homeList) {
    commit(SET_HOMELIST, homeList);
  },
  setCurrentHome({ commit }, currentHome) {
    commit(SET_CURRENTHOME, currentHome);
  },
};

const getters = {
  getOrgId: (state) => state.orgId,
  getOrgName: (state) => state.orgName,
  getTreeId: (state) => state.treeId,
  getTreeName: (state) => state.treeName,

  getHomeList:(state)=>state.homeList,
  getCurrentHome:(state)=>state.currentHome,
  getTreeLevel:(state)=>state.treeLevel,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
