<template>
  <div id="app">
    <layout v-if="!isLogin" />
    <router-view v-if="isLogin"></router-view>
  </div>
</template>

<script>
import layout from './layout/index.vue';
export default {
  components: {
    layout,
  },
  mounted() {
    let currentHost = window.location.host
    if (currentHost.includes('www.')) {
      currentHost = currentHost.replace('www.', '')
    }
    let area = currentHost.split('pontosense')[0].split('.')[0]
    if (area) {
      return
    } else {
      //访问pontosense.cloud 跳转至ca.pontosense.cloud
      if (currentHost.includes('cloud')) {
        let host = 'ca.pontosense.cloud'
        window.location.href = `https://${host}`
      } else if (currentHost.includes('dev')) {
        let host = 'ca.pontosense.dev'
        window.location.href = `https://${host}`
      }
    }

  },
  computed: {
    isLogin() {
      return this.$route.path === '/' || this.$route.path === '/login'
    }
  },
};
</script>

<style lang="scss">
@import '@/styles/variables.scss';

@font-face {
  font-family: 'DINAlternate';
  src: url('./assets/font/DIN.ttf');
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: PingFang SC, Helvetica, sans-serif;

}

#app {
  min-width: 1380px;
  min-height: 720px;
  overflow: auto;
  font-family: PingFang SC, Helvetica, sans-serif;
}


[popover-text]::after,
[popover-text]::before {
  position: absolute;
  top: 50%;
  opacity: 0;
  z-index: 999;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
}

[popover-text]:hover::after,
[popover-text]:hover::before {
  opacity: 1;
  visibility: visible;
}

[popover-text]:after {
  content: attr(popover-text);
  right: -5px;
  transform: translate(100%, -50%);
  width: fit-content;
  height: fit-content;
  font-size: 12px;
  padding: 3px 7px;
  color: #ffffff;
  background: #333333;
  border-radius: 5px;
  white-space: pre;
  cursor: default;
  opacity: 0;
  z-index: 999;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
}

[popover-text]::before {
  content: '';
  right: -1px;
  transform: translate(50%, -50%);
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-right-color: #333333;
}

[popover-text] {
  position: relative;
}

.el-select-dropdown {
  font-family: PingFang SC, Helvetica, sans-serif;

  span {
    font-family: PingFang SC, Helvetica, sans-serif;
  }
}


.el-date-range-picker {
  font-family: PingFang SC, Helvetica, sans-serif;
}

.el-tooltip__popper {
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
}


// home switch
.el-dropdown-menu.el-popper {
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 10px 10px 0 rgba(85, 88, 181, 0.1);
  border: solid 1px #eaeaf2;
  background-color: #fff;
  font-family: PingFang SC, Helvetica, sans-serif;


  .popper__arrow {
    display: none;
  }



  .el-dropdown-menu__item {
    height: 40px;
    border-radius: 5px;
    color: #222e61;
    font-size: 14px;
    padding: 0 40px 0 10px;


    >.server-item {
      display: flex;
      align-items: center;
      padding: 0 10px;

      >.ic-flag {
        display: flex;
        align-items: center;
        width: 25px;
        height: 25px;
        margin-right: 10px;

        >img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .el-dropdown-menu__item:hover {
    background-color: rgba(121, 130, 166, 0.15);
    color: #222e61;

  }

  .current {
    position: relative;
    color: #325bf2;
    font-size: 14px;
    font-weight: 500;
  }

  .current::after {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    content: "";
    width: 18px;
    height: 18px;
    background: url("./assets/icon/ic_current_home.png") no-repeat center;
    background-size: contain;
  }

  .current:hover {
    color: #325bf2;

    .current::after {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
      content: "";
      width: 15px;
      height: 15px;
      background: url("./assets/icon/ic_current_home.png") no-repeat center;
      background-size: contain;
    }
  }
}



//server
.el-dropdown-menu.el-popper.server-select {
  padding: 5px;
  font-family: PingFang SC, Helvetica, sans-serif;


  .el-dropdown-menu__item {
    width: 260px;
    display: flex;
    align-items: center;
    padding: 8px;
    font-family: PingFang SC, Helvetica, sans-serif;

  }
}
</style>