<template>
    <div class="upgrade-mask__wrapper">
        <div class="upgrade-popup__wrapper">
            <div class="upgrade-img"></div>
            <div class="upgrade-title" v-if="status === 0 || status === 1">{{ $t('p0011sysupingtt') }}</div>
            <div class="upgrade-text" v-if="status === 0">{{ $t('p0011sysupingmsg') }}</div>
            <div class="countdown-time" v-if="status === 0">{{ countdownText }}</div>
            <div class="upgrade-text" v-if="status === 1">{{ $t('p0011sysuptomsg') }}</div>
            <div class="upgrade-title" v-if="status === 2">{{ $t('p0011sysupedtt') }}</div>
        </div>
    </div>
</template>

<script>
// import store from '@/store';
import { mapMutations } from 'vuex';

export default {
    name: "UpgradeMask",
    data() {
        return {
            status: 0,//0:倒计时 1:超时 2:成功
            timer: null,//过渡倒计时
            checkTimer: null,//检查升级状态
            countdownText: ''
        }
    },
    mounted() {
        // 过渡倒计时
        this.timer = setInterval(() => {
            this.formatTime()
            if (this.status === 1) {
                clearInterval(this.timer);//停止倒计时
                this.timer = null;
            }
        }, 1000)

        // 检查是否升级成功
        this.checkTimer = setInterval(() => {
            this.checkUpgrading()
        }, 3000)
    },
    computed: {
        endTime() {
            return Number(this.$store.state.system.upgradeTime) + Number(300);
        },

    },
    methods: {
        formatTime() {
            let currentTime=Number(Math.floor(new Date().getTime() / 1000))
            let timeDifference = this.endTime - currentTime

            if (timeDifference > 0) {
                let minutes = Math.floor(timeDifference / 60);
                let seconds = timeDifference % 60;
                // 格式化输出，确保两位数
                let formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
                let formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
                this.countdownText = `${formattedMinutes}:${formattedSeconds}`;
            } else {
                this.status = 1
            }

        },
        checkUpgrading() {
            this.$http.getUpgrade()
                .then(res => {
                    if (res && res?.code === 200) {
                        const { data } = res
                        if (data.updateType === 2) {//升级已经成功
                            clearInterval(this.checkTimer);
                            this.checkTimer = null;
                            clearInterval(this.timer);
                            this.timer = null;
                            this.status = 2//升级成功 显示成功界面

                            setTimeout(() => {
                                this.SET_UPGRADTING(false)
                                this.SET_BANNER("")
                                this.$emit('upgradeSuccess')
                            }, 3000)
                        }

                    }
                })
        },
        ...mapMutations(['SET_UPGRADTING', 'SET_BANNER']),



    }
}




</script>

<style lang="scss" scoped>
.upgrade-mask__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999;

    >.upgrade-popup__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 480px;
        border-radius: 10px;
        background-color: #fff;
        padding: 40px;

        >.upgrade-img {
            width: 100px;
            height: 100px;
            background: url('../../assets/img/img-upgrading.png') no-repeat center;
            background-size: contain;
        }

        >.upgrade-title {
            font-size: 16px;
            font-weight: 500;
            color: #222e61;
            margin-top: 20px;

        }

        >.upgrade-text {
            text-align: center;
            color: #7982a6;
            font-size: 14px;
            margin-top: 20px;
            white-space: pre-line;

        }

        >.countdown-time {
            font-size: 30px;
            font-weight: 500;
            color: #222e61;
            margin-top: 30px;

        }

    }
}
</style>