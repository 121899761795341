import axios from "./api";
import { trimObjItemValue } from "@/utils/util"; //去除空格
import toast from "@/components/toast/index";

import { translate } from "@/locales/index";
import store from "@/store";

// get参数编码  将传入的参数编码为url中的查询字符串，formatter url
function encodeURIParams(config) {
  let url = config.url;
  url += "?";
  if (config.params) {
    const keys = Object.keys(config.params);
    for (const key of keys) {
      url += `${key}=${encodeURIComponent(config.params[key])}&`;
    }
  }
  url = url.substring(0, url.length - 1);
  config.params = {};
  return url; //https://example.com ? name=John & age=25
}

const codeList=[10011,10012,20013,20101,20021,20102,30031,60002,10013,40011,40009]

// 请求方法
const Methods = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
  patch: "patch",
};

const request = (options) => {
  options.method = options.method || Methods.get; //默认为get请求
  let key = "data";
  if (options.method === Methods.get || options.method === Methods.delete) {
    key = "params";
    options.url = encodeURIParams(options); //格式化url
    // avoid cache in ie.    ie浏览器中发送相同的请求，可能会缓存之前的结果 不会重新请求
    if (options.url.includes("?")) {
      options.url += `&t=${Date.now()}`;
    } else {
      options.url += `?t=${Date.now()}`;
    }
  }

  let params;
  if (options.params instanceof FormData) {
    // do not handle formdata
    params = options.params;
  } else {
    if (options.params) {
      params = trimObjItemValue(options.params);
    }
  }
  return axios({
    url: options.url,
    method: options.method,
    headers: options.headers,
    [key]: params,
  })
    .then((response) => {
      const { data } = response;
      if (data) {
        const { code } = data;
        if (code && code !== 200) {
          if (codeList.includes(code)) {//A010c报错 用户可以自行处理的报错
            toast(translate(code));
          } else {
            //A010c报错 无对应message的报错
            toast(translate("p0010ercddft"), code, );
          }
          return Promise.reject(response);
        }
      }
      return response.data;
    })
    .catch((error) => {
      const upgradeStatus = store.state.system.isUpgradting;
      const response = error.response;
      if (response) {
        if (response.status === 401) {//鉴权未通过 跳转重新登录
          localStorage.removeItem("token");
          localStorage.removeItem("orgId");
          localStorage.removeItem("orgName");
          localStorage.removeItem("username");
          localStorage.removeItem("role");
          localStorage.removeItem("homeList");
          localStorage.removeItem("currentHome");
          localStorage.removeItem("belongTo");
          localStorage.removeItem("userId");
          window.location.href = "/";
          return;
        }

        if (response.status !== 200) {
          if (upgradeStatus) return;//避乱升级中轮询报错

          if (
            Number(response.status) >= 400 &&
            Number(response.status) <= 599
          ) {
            toast(translate("p0010reqerrdes"), response.status);//A010b报错
          }
        }
      }
    });
};

const Http = {
  getServerList(language){
    return request({
      url:`/system/server/saas/list?language=${language}`,
      method:Methods.get,
    })
  },
  getUpgrade() {
    return request({
      url: `/saas/user/upgradeSuccess`,
      method: Methods.get,
    });
  },
  login(params) {
    return request({
      url: `/saas/user/login`,
      method: Methods.post,
      params,
    });
  },
  logout() {
    return request({
      url: `/saas/user/logout`,
      method: Methods.get,
    });
  },
  // 设置当前home
  setHome(homeId) {
    return request({
      url: `/saas/user/switchHome/${homeId}`,
      method: Methods.get,
    });
  },
  getHomeList() {
    return request({
      url: `/saas/user/home/list`,
      method: Methods.get,
    });
  },
  // Dashboard获取房间列表
  getRoomList() {
    return request({
      url: `/saas/home/room/list`,
      method: Methods.get,
    });
  },
  //Dashboard 通知栏-获取房间状态栏信息
  getRoomStatus() {
    return request({
      url: `/saas/home/room/queryRoomInfo`,
      method: Methods.get,
    });
  },
  // Dashboard获取房间occupied弹窗列表
  getRoomOccupiedInfo() {
    return request({
      url: `/saas/home/room/current/status/list`,
      method: Methods.get,
    });
  },
  // Dashboard获取offline弹窗列表
  getOfflineTable() {
    return request({
      url: `/saas/home/room/offlineList`,
      method: Methods.get,
    });
  },
  // Dashboardd获取fall弹窗列表
  getFallTable(params) {
    return request({
      url: `/saas/home/room/queryFall`,
      method: Methods.post,
      params,
    });
  },
  // 获取history of fall列表
  getFallHistoryList(params) {
    return request({
      url: `/saas/home/room/queryFallenMsg`,
      method: Methods.post,
      params,
    });
  },
  // 获取房间
  getRoomFall(roomId) {
    return request({
      url: `/saas/home/room/fallHistory/${roomId}`,
      method: Methods.get,
    });
  },

  // 阅读单条消息
  readSingleMessage(params) {
    return request({
      url: `/saas/home/room/messageLog/update`,
      method: Methods.post,
      params,
    });
  },

  // 标记所有消息为已读
  markAllMessage(roomId) {
    return request({
      url: `/saas/home/room/markAll/${roomId}`,
      method: Methods.get,
    });
  },

  // 获取房间详情页头部信息
  getRoomDetailStatus(roomId) {
    return request({
      url: `/saas/home/room/detail/${roomId}`,
      method: Methods.get,
    });
  },

  // 获取指定房间的版本号
  getVersion(roomId) {
    return request({
      url: `/saas/sensor/version/${roomId}`,
      method: Methods.get,
    });
  },

  // 获取更多跌倒消息
  getMoreFallInfo(isRead, pageNo, pageSize, roomId, minId) {
    return request({
      url: `/saas/home/room/detail/fall/list?isRead=${isRead}&pageNo=${pageNo}&pageSize=${pageSize}&roomId=${roomId}&minMessageId=${minId}`,
      method: Methods.get,
    });
  },

  // logBook
  queryLogBook(params) {
    return request({
      url: `/saas/room/detail/log`,
      method: Methods.post,
      params,
    });
  },
  getGanttList(startTime, endTime) {
    return request({
      url: `/saas/room/detail/getAllHomeGantt?startTime=${startTime}&endTime=${endTime}`,
      method: Methods.get,
    });
  },
  getRoomInfo(id) {
    return request({
      url: `/saas/room/detail/getRoom/${id}`,
      method: Methods.get,
    });
  },

  // sensor 列表
  querySensorList(params) {
    return request({
      url: `/saas/sensor/list`,
      method: Methods.post,
      params,
    });
  },
  // 修改传感器信息
  updateSensorInfo(params) {
    return request({
      url: `/saas/sensor/info/update`,
      method: Methods.put,
      params,
    });
  },

  // 获取sensor默认参数信息
  getSensorParam(query) {
    return request({
      url: `/saas/sensor/location/default/get/${query}`,
      method: Methods.get,
    });
  },

  // 修改传感器位置参数信息
  updateSensorLocation(params) {
    return request({
      url: `/saas/sensor/location/update`,
      method: Methods.put,
      params,
    });
  },

  // 删除传感器
  deleteSensor(sensorId) {
    return request({
      url: `/saas/sensor/reset/${sensorId}`,
      method: Methods.delete,
    });
  },
  // 获取可升级的sensor列表
  getUpgradeSensorList(params) {
    return request({
      url: `/saas/sensor/upgrade/list`,
      method: Methods.post,
      params,
    });
  },
  // 固件批量升级
  updateFirmware(params) {
    return request({
      url: `/saas/sensor/upgrade/manual`,
      method: Methods.post,
      params,
    });
  },
  // room选择器，获取roomlist
  getRoomSelectOption(params) {
    let url;
    if (params.isbind === undefined) {
      url = `/saas/room/dropDown/list?`;
    } else {
      url = `/saas/room/dropDown/list?isBind=${params.isbind}`;
    }
    if (params.query) {
      url += `&query=${params.query}`;
    }
    if (params.id) {
      url += `&id=${params.id}`;
    }
    return request({
      url: url,
      method: Methods.get,
    });
  },
  // 修改设备绑定的房间
  updateSensorbindRoom(sensorId, roomId) {
    return request({
      url: `/saas/sensor/room/bind?sensorId=${sensorId}&roomId=${roomId}`,
      method: Methods.post,
    });
  },
  updateSensorConfig(params) {
    return request({
      url: `/saas/sensor/seconds/update`,
      method: Methods.put,
      params,
    });
  },

  // room
  queryRoomList(params) {
    return request({
      url: `/saas/room/list`,
      method: Methods.post,
      params,
    });
  },
  // 修改房间绑定的sensor
  updateRoomSensor(roomId, sensorId) {
    return request({
      url: `/saas/room/bind/sensor?roomId=${roomId}&sensorId=${sensorId}`,
      method: Methods.post,
    });
  },

  updateRoomInfo(params) {
    return request({
      url: `/saas/room/info/update`,
      method: Methods.put,
      params,
    });
  },

  delRoom(roomId) {
    return request({
      url: `/saas/room/delete/${roomId}`,
      method: Methods.delete,
    });
  },

  createRoom(params) {
    return request({
      url: `/saas/room/add`,
      method: Methods.post,
      params,
    });
  },

  // floor

  // 获取未绑定房间的设备列表
  getSensorOption(query, isBind) {
    return request({
      url: `/saas/sensor/dropDown/list?query=${query}&isBind=${isBind}`,
      method: Methods.get,
    });
  },

  getFloorOption(orgId) {
    return request({
      url: `/saas/floor/FloorList/${orgId}`,
      method: Methods.get,
    });
  },

  getFloorList(params) {
    return request({
      url: `/saas/floor/list`,
      method: Methods.post,
      params,
    });
  },
  updateFloorInfo(params) {
    return request({
      url: `/saas/floor/edit`,
      method: Methods.post,
      params,
    });
  },

  delFloor(floorId) {
    return request({
      url: `/saas/floor/delete/${floorId}`,
      method: Methods.delete,
    });
  },
  createFloor(params) {
    return request({
      url: `/saas/floor/add`,
      method: Methods.post,
      params,
    });
  },

  // organization management
  getTreeList(queryAll, orgId) {
    return request({
      url: `/saas/org/treeList?isQryAll=${queryAll}&orgId=${orgId}`,
      method: Methods.get,
    });
  },
  createHome(params) {
    return request({
      url: `/saas/org/agency/add`,
      method: Methods.post,
      params,
    });
  },
  deleteHome(orgId) {
    return request({
      url: `/saas/org/${orgId}`,
      method: Methods.delete,
    });
  },
  renameOrg(params) {
    return request({
      url: `/saas/org`,
      method: Methods.put,
      params,
    });
  },
  delOrg(orgId) {
    return request({
      url: `/saas/org/${orgId}`,
      method: Methods.delete,
    });
  },

  getSuperAdminList(orgId, pageNo, pageSize, query) {
    return request({
      url: `/saas/org/superAdminList/${orgId}?pageNo=${pageNo}&pageSize=${pageSize}&query=${query}`,
      method: Methods.get,
    });
  },

  getOrgUser(orgId, pageNo, pageSize, query) {
    return request({
      url: `/saas/org/userList/${orgId}?pageNo=${pageNo}&pageSize=${pageSize}&query=${query}`,
      method: Methods.get,
    });
  },
  addSuperAdmin(params) {
    return request({
      url: `/saas/org/addSpUser`,
      method: Methods.post,
      params,
    });
  },
  delUser(userId) {
    return request({
      url: `/saas/org/delete/user/${userId}`,
      method: Methods.delete,
    });
  },
  addDepartUser(params) {
    return request({
      url: `/saas/org/addDeptUser`,
      method: Methods.post,
      params,
    });
  },
  editUserRole(params) {
    return request({
      url: `/saas/org/updateUserRole`,
      method: Methods.post,
      params,
    });
  },
  editUserHome(params) {
    return request({
      url: `/saas/org/updateUserHome`,
      method: Methods.post,
      params,
    });
  },
  editUserPwd(params) {
    return request({
      url: `/saas/org/update/user/password`,
      method: Methods.put,
      params,
    });
  },
  getHomeUser(homeId, pageNo, pageSize, query) {
    return request({
      url: `/saas/org/getHomeUserList/${homeId}?pageNo=${pageNo}&pageSize=${pageSize}&query=${query}`,
      method: Methods.get,
    });
  },
};

Http.install = function (_Vue) {
  _Vue.prototype.$http = Http;
};
export default Http;
